import OneLogo from './one_logo.svg'

const N3DoF_Cover_pc = `${process.env.resourceUrl}images/one/N3DoF_Cover_pc.webp`
const N3DoF_Cover_mobile = `${process.env.resourceUrl}images/one/N3DoF_Cover_mobile.webp`
const firstScreen_pc =  `${process.env.resourceUrl}images/one/firstScreen_pc.webp`
const firstScreen_mobile =  `${process.env.resourceUrl}images/one/firstScreen_mobile.webp`
const morethan_pc =  `${process.env.resourceUrl}images/one/morethan_pc.webp`
const morethan_mobile =  `${process.env.resourceUrl}images/one/morethan_mobile.webp`
const device_ipad =  `${process.env.resourceUrl}images/one/device_ipad.webp`
const device_android =  `${process.env.resourceUrl}images/one/device_android.webp`
const device_iphone  =  `${process.env.resourceUrl}images/one/device_iphone.webp`
const device_XBOX  =  `${process.env.resourceUrl}images/one/device_XBOX.webp`
const device_steamdeck  =  `${process.env.resourceUrl}images/one/device_steamdeck.webp`
const device_switch  =  `${process.env.resourceUrl}images/one/device_switch.webp`
const device_ps5  =  `${process.env.resourceUrl}images/one/device_ps5.webp`
const device_mac  =  `${process.env.resourceUrl}images/one/device_mac.webp`
const device_drone  =  `${process.env.resourceUrl}images/one/device_drone.webp`
const device_rog  =  `${process.env.resourceUrl}images/one/device_rog.webp`
const ar4all_mask1  =  `${process.env.resourceUrl}images/one/ar4all_mask1.webp`
const ar4all_mask2  =  `${process.env.resourceUrl}images/one/ar4all_mask2.webp`
const AR4ALL_BG_Desktop  =  `${process.env.resourceUrl}images/one/AR4ALL_BG_Desktop.webp`
const AR4ALL_BG_Mobile  =  `${process.env.resourceUrl}images/one/AR4ALL_BG_Mobile.webp`
const Highlight_Bose  =  `${process.env.resourceUrl}images/one/Highlight_Bose.svg`
const TUV_ColorAccuracy  =  `${process.env.resourceUrl}images/one/TUV_ColorAccuracy.svg`
const TUV_Eyecomfort  =  `${process.env.resourceUrl}images/one/TUV_Eyecomfort.svg`
const TUV_HighVisibility  =  `${process.env.resourceUrl}images/one/TUV_HighVisibility.svg`
const TUV_LowBlueLight  =  `${process.env.resourceUrl}images/one/TUV_LowBlueLight.svg`
const one_lenses  =  `${process.env.resourceUrl}images/one/one_lenses.webp`
const logo_frameofchoic  =  `${process.env.resourceUrl}images/one/logo_frameofchoic.webp`
const logo_lensology  =  `${process.env.resourceUrl}images/one/logo_lensology.webp`
const Adjustable_Clear  =  `${process.env.resourceUrl}images/one/Adjustable_Clear.svg`
const Adjustable_Shade  =  `${process.env.resourceUrl}images/one/Adjustable_Shade.svg`
const Adjustable_Theater  =  `${process.env.resourceUrl}images/one/Adjustable_Theater.svg`
const one_sound_logo  =  `${process.env.resourceUrl}images/one/one_sound_logo.svg`
const Spec_One  =  `${process.env.resourceUrl}images/one/Spec_One.webp`
const Spec_OnePro  =  `${process.env.resourceUrl}images/one/Spec_OnePro.webp`
const ID_1_Desktop  =  `${process.env.resourceUrl}images/one/ID_1_Desktop.webp`
const ID_2_Desktop  =  `${process.env.resourceUrl}images/one/ID_2_Desktop.webp`
const ID_3_Desktop  =  `${process.env.resourceUrl}images/one/ID_3_Desktop.webp`
const ID_4_Desktop  =  `${process.env.resourceUrl}images/one/ID_4_Desktop.webp`
const ID_5_Desktop  =  `${process.env.resourceUrl}images/one/ID_5_Desktop.webp`
const ID_6_Desktop  =  `${process.env.resourceUrl}images/one/ID_6_Desktop.webp`
const Highlight_3dof  =  `${process.env.resourceUrl}images/one/Highlight_3dof.webp`
const Highlight_3dof_mobile  =  `${process.env.resourceUrl}images/one/Highlight_3dof_mobile.webp`
const Highlight_FOV  =  `${process.env.resourceUrl}images/one/Highlight_FOV.webp`
const Highlight_FOV_mobile  =  `${process.env.resourceUrl}images/one/Highlight_FOV_mobile.webp`
const Highlight_X1  =  `${process.env.resourceUrl}images/one/Highlight_X1.webp`
const Highlight_X1_Mobile  =  `${process.env.resourceUrl}images/one/Highlight_X1_Mobile.webp`
const Highlight_audio  =  `${process.env.resourceUrl}images/one/Highlight_audio.webp`
const Highlight_audio_mobile  =  `${process.env.resourceUrl}images/one/Highlight_audio_mobile.webp`
const Highlight_display_mobile  =  `${process.env.resourceUrl}images/one/Highlight_display_mobile.webp`
const Highlight_display  =  `${process.env.resourceUrl}images/one/Highlight_display.webp`
const Highlight_lens  =  `${process.env.resourceUrl}images/one/Highlight_lens.webp`
const Highlight_lens_mobile  =  `${process.env.resourceUrl}images/one/Highlight_lens_mobile.webp`
const Highlight_nits  =  `${process.env.resourceUrl}images/one/Highlight_nits.webp`
const Highlight_nits_mobile  =  `${process.env.resourceUrl}images/one/Highlight_nits_mobile.webp`
const Highlight_ultrawide  =  `${process.env.resourceUrl}images/one/Highlight_ultrawide.webp`
const Highlight_ultrawide_mobile  =  `${process.env.resourceUrl}images/one/Highlight_ultrawide_mobile.webp`
const GF_Follow_TrueStability  =  `${process.env.resourceUrl}images/one/GF_Follow_TrueStability.webp`
const X1_3ms  =  `${process.env.resourceUrl}images/one/X1_3ms.webp`
const one_x1_video_1  =  `${process.env.resourceUrl}images/one/one_x1_video_1.mp4`
const one_x1_video_2  =  `${process.env.resourceUrl}images/one/one_x1_video_2.mp4`
const one_x1_video_3  =  `${process.env.resourceUrl}images/one/one_x1_video_3.mp4`
const one_x1_video_4  =  `${process.env.resourceUrl}images/one/one_x1_video_4.mp4`
const GF_FOV_video  =  `${process.env.resourceUrl}images/one/GF_FOV_video.mp4`
const chip_1  =  `${process.env.resourceUrl}images/one/chip_1.webp`
const chip_2  =  `${process.env.resourceUrl}images/one/chip_2.webp`
const chip_3  =  `${process.env.resourceUrl}images/one/chip_3.webp`
const chip_3_1  =  `${process.env.resourceUrl}images/one/chip_3_1.webp`
const chip_3_2  =  `${process.env.resourceUrl}images/one/chip_3_2.webp`
const ExpandedVisualExperience_Cover_Desktop  =  `${process.env.resourceUrl}images/one/ExpandedVisualExperience_Cover_Desktop.webp`
const ExpandedVisualExperience_Cover_Mobile  =  `${process.env.resourceUrl}images/one/ExpandedVisualExperience_Cover_Mobile.webp`
const one_tuv  =  `${process.env.resourceUrl}images/one/one_tuv.webp`
const bose_Cover_Desktop  =  `${process.env.resourceUrl}images/one/bose_Cover_Desktop.webp`
const bose_Cover_Mobile  =  `${process.env.resourceUrl}images/one/bose_Cover_Mobile.webp`
const Bose  =  `${process.env.resourceUrl}images/one/Bose.webp`
const Bose_mobile  =  `${process.env.resourceUrl}images/one/Bose_mobile.webp`
const Eye_Cover_Desktop  =  `${process.env.resourceUrl}images/one/Eye_Cover_Desktop.webp`
const Eye_Cover_Mobile  =  `${process.env.resourceUrl}images/one/Eye_Cover_Mobile.webp`
const madetofit_Cover_Desktop  =  `${process.env.resourceUrl}images/one/madetofit_new_desktop.webp`
const madetofit_Cover_Mobile  =  `${process.env.resourceUrl}images/one/madetofit_new_mobile.webp`
const adjustable_bg  =  `${process.env.resourceUrl}images/one/adjustable_bg.webp`
const sound_bose_bg1  =  `${process.env.resourceUrl}images/one/sound_bose_bg1.mp4`
const sound_bose_bg2  =  `${process.env.resourceUrl}images/one/sound_bose_bg2.webp`
const sound_bose_bg3  =  `${process.env.resourceUrl}images/one/sound_bose_bg3.webp`
const spec_one  =  `${process.env.resourceUrl}images/one/spec_One.webp`
const spec_onepro  =  `${process.env.resourceUrl}images/one/spec_OnePro.webp`
const ScenePic_Gaming  =  `${process.env.resourceUrl}images/one/ScenePic_Gaming.webp`
const ScenePic_productivity  =  `${process.env.resourceUrl}images/one/ScenePic_productivity.webp`
const ScenePic_Projector  =  `${process.env.resourceUrl}images/one/ScenePic_Projector.webp`
const ScenePic_0  =  `${process.env.resourceUrl}images/one/ScenePic_0.webp`
const ScenePic_1  =  `${process.env.resourceUrl}images/one/ScenePic_1.webp`
const ScenePic_2  =  `${process.env.resourceUrl}images/one/ScenePic_2.webp`
const ScenePic_3  =  `${process.env.resourceUrl}images/one/ScenePic_3.webp`
const ScenePic_4  =  `${process.env.resourceUrl}images/one/ScenePic_4.webp`
const ScenePic_5  =  `${process.env.resourceUrl}images/one/ScenePic_5.webp`
const ScenePic_6  =  `${process.env.resourceUrl}images/one/ScenePic_6.webp`
const ScenePic_7  =  `${process.env.resourceUrl}images/one/ScenePic_7.webp`
const GF_Anchor_1 =  `${process.env.resourceUrl}images/one/GF_Anchor_1.webp`
const GF_Anchor_2 =  `${process.env.resourceUrl}images/one/GF_Anchor_2.webp`
const GF_Follow_1 =  `${process.env.resourceUrl}images/one/GF_Follow_1.webp`
const GF_SideView_1 =  `${process.env.resourceUrl}images/one/GF_SideView_1.webp`
const GF_Ultrawide_1 =  `${process.env.resourceUrl}images/one/GF_Ultrawide_1.webp`
const XREALeye =  `${process.env.resourceUrl}images/one/XREALeye.mp4`
const HONSVRWhite =  `${process.env.resourceUrl}images/one/HONSVRWhite.png`
const Accessories_Eye =  `${process.env.resourceUrl}images/one/Accessories_Eye.webp`
const Accessories_Frame =  `${process.env.resourceUrl}images/one/Accessories_Frame.webp`
const Native3DoF_video =  `${process.env.resourceUrl}images/one/Native3DoF_video.mp4`
const Highlight_audio_JP =  `${process.env.resourceUrl}images/one/Highlight_audio_JP.webp`
const Highlight_audio_mobile_JP =  `${process.env.resourceUrl}images/one/Highlight_audio_mobile_JP.webp`
const OSD_JP =  `${process.env.resourceUrl}images/one/OSD_JP.webp`
const Bose_JP =  `${process.env.resourceUrl}images/one/Bose_JP.webp`
const compare_onepro =  `${process.env.resourceUrl}images/compare/compare_onepro.webp`
const compare_one =  `${process.env.resourceUrl}images/compare/compare_one.webp`
const Ginza_jp =  `${process.env.resourceUrl}images/one/Ginza_jp.png`
const mask_black =  `${process.env.resourceUrl}images/one/mask_black.webp`
const mask_white =  `${process.env.resourceUrl}images/one/mask_white.webp`
const mask_grey =  `${process.env.resourceUrl}images/one/mask_grey.webp`
const GF_FOV_Mobile  =  `${process.env.resourceUrl}images/one/GF_FOV_Mobile.webp`

import X1_icon1 from './X1_icon1.svg'
import X1_icon2 from './X1_icon2.svg'
import X1_icon3 from './X1_icon3.svg'
import moreSVG from './more.svg'
import heel from './heel.svg'
import heel_arrows from './heel_arrows.svg'

export{
    Bose_mobile,
    mask_grey,
    mask_black,
    mask_white,
    Ginza_jp,
    compare_onepro,
    compare_one,
    Bose_JP,
    OSD_JP,
    Highlight_audio_JP,
    Highlight_audio_mobile_JP,
    Native3DoF_video,
    Accessories_Eye,
    Accessories_Frame,
    HONSVRWhite,
    XREALeye,
    GF_Anchor_1,
    GF_Anchor_2,
    GF_Follow_1,
    GF_SideView_1,
    GF_Ultrawide_1,
    ScenePic_Gaming,
    ScenePic_Projector,
    ScenePic_productivity,
    ScenePic_0,
    ScenePic_1,
    ScenePic_2,
    ScenePic_3,
    ScenePic_4,
    ScenePic_5,
    ScenePic_6,
    ScenePic_7,
    spec_one,
    spec_onepro,
    sound_bose_bg1,
    sound_bose_bg2,
    sound_bose_bg3,
    adjustable_bg,
    madetofit_Cover_Desktop,
    madetofit_Cover_Mobile,
    Eye_Cover_Desktop,
    Eye_Cover_Mobile,
    Bose,
    bose_Cover_Desktop,
    bose_Cover_Mobile,
    one_tuv,
    GF_FOV_video,
    ExpandedVisualExperience_Cover_Mobile,
    ExpandedVisualExperience_Cover_Desktop,
    chip_1,
    chip_2,
    chip_3,
    chip_3_1,
    chip_3_2,
    one_x1_video_1,
    one_x1_video_2,
    one_x1_video_3,
    one_x1_video_4,
    X1_3ms,
    GF_Follow_TrueStability,
    Highlight_3dof,
    Highlight_3dof_mobile,
    Highlight_FOV,
    Highlight_FOV_mobile,
    Highlight_X1,
    Highlight_X1_Mobile,
    Highlight_audio,
    Highlight_audio_mobile,
    Highlight_display,
    Highlight_display_mobile,
    Highlight_lens,
    Highlight_lens_mobile,
    Highlight_nits,
    Highlight_nits_mobile,
    Highlight_ultrawide,
    Highlight_ultrawide_mobile,
    ID_1_Desktop,
    ID_2_Desktop,
    ID_3_Desktop,
    ID_4_Desktop,
    ID_5_Desktop,
    ID_6_Desktop,
    Spec_One,
    Spec_OnePro,
    one_sound_logo,
    Adjustable_Clear,
    Adjustable_Shade,
    Adjustable_Theater,
    moreSVG,
    logo_frameofchoic,
    logo_lensology,
    one_lenses,
    TUV_ColorAccuracy,
    TUV_Eyecomfort,
    TUV_LowBlueLight,
    TUV_HighVisibility,
    Highlight_Bose,
    AR4ALL_BG_Desktop,
    AR4ALL_BG_Mobile,
    ar4all_mask1,
    ar4all_mask2,
    N3DoF_Cover_pc,
    N3DoF_Cover_mobile,
    firstScreen_mobile,
    firstScreen_pc,
    OneLogo,
    morethan_pc,
    morethan_mobile,
    device_ipad,
    device_android,
    device_iphone,
    device_XBOX,
    device_steamdeck,
    device_switch,
    device_ps5,
    device_mac,
    device_drone,
    device_rog,
    X1_icon1,
    X1_icon2,
    X1_icon3,
    GF_FOV_Mobile,
    heel,
    heel_arrows
}